.center {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 97vh;
}

h1 {
    text-align: center;
    font-size: 80px;
    font-family: Arial;
}

.blur {
    filter: blur(30px);
}

.red-dot {
    background-color: red;
    width: 30px;
    height: 30px;
    position: absolute;
    top: 10%;
    right: 20%;
}

.blue-dot {
    background-color: blue;
    width: 30px;
    height: 30px;
    position: absolute;
    top: 75%;
    left: 26%;
}

.top-blue-dot {
    background-color: #0f0f8f;
    width: 30px;
    height: 30px;
    position: absolute;
    top: 30%;
    left: 12%;
}


.big-purple-dot {
    background-color: rgba(128, 0, 128, 0.56);
    width: 200px;
    height: 200px;
    position: absolute;
    top: 30%;
    left: 47%;
    filter: blur(86px)
}
